/* ReportScreen.css */
.report-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.data-table {
  width: 100%;
}

/* Style for the BarChart container */
.bar-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* You may need to apply additional styles to fine-tune your chart's layout */
/* Styles for the filter container */
.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Align selectors vertically */
  justify-content: space-between; /* Distribute space between selectors */
}

/* Style each individual selector */
.filter-container .Select,
.filter-container .react-datepicker-wrapper {
  flex-grow: 1;
}

/* Specific styles for the date range picker */
.filter-container .react-datepicker-wrapper {
  display: flex;
}

/* If you want to style the date input specifically */
.filter-container .react-datepicker-wrapper input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc; /* Add border, change color as needed */
  border-radius: 4px; /* Rounded corners for the input field */
}
