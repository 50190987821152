.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full screen height */
    background: url('/public/background.png') no-repeat center center fixed; /* Background image */
    background-size: cover;
    color: white; /* Ensures text is visible on background */
}

.login-box {
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px; /* Adjust width as needed */
}

.login-container h2 {
    margin-bottom: 10px;
    color: black; /* Ensure the heading text is highlighted */
    font-size: 24px;
    text-align: center;
}

.tab-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.tab-container .tab {
    background: white; /* Default background for tabs */
    color: black; /* Default text color for tabs */
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px 5px 0 0; /* Rounded top corners for tabs */
    outline: none;
    font-size: 16px;
    flex: 1;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background and text color */
}

.tab-container .tab:hover {
    background: #007BFF; /* Background color on hover */
    color: white; /* Text color on hover */
}

.tab-container .tab.active {
    background: #007BFF; /* Active tab color */
    color: white;
}

.login-box form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-box label {
    margin-top: 10px;
    font-weight: bold;
    color: black; /* Ensure the label text is visible */
}

.login-box input {
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: black; /* Text color inside the input field */
    background-color: rgba(255, 255, 255, 0.9); /* Light background for input field */
}

.login-box input::placeholder {
    color: #aaa; /* Placeholder text color */
}

.login-box button {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #007BFF; /* Button color */
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease; /* Smooth transition for button hover */
}

.login-box button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.login-box button:disabled {
    background-color: #ccc; /* Disabled button color */
    cursor: not-allowed;
}

.forgot-password {
    margin-top: 10px;
    color: #007BFF;
    cursor: pointer;
    text-align: center; /* Center align the text */
    font-weight: none; /* Make the text bold */
    text-shadow: none; /* Remove shadow for better readability */
}

.forgot-password:hover {
    color: #0056b3;
    text-decoration: underline;
}

.terms {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    color: black;
    width: 100%;
}

.terms a {
    color: #007BFF;
    text-decoration: none;
}

.terms a:hover {
    text-decoration: underline;
}