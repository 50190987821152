/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@aws-amplify/ui-react/styles.css";

.markdown > * {
  all: revert;
}
/*
 * Button base styles
 */
.amplify-button {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-border-color
  );
  --amplify-internal-button-color: var(--amplify-components-button-color);
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-focus-box-shadow
  );
  --amplify-internal-button-border-width: var(
    --amplify-components-button-border-width
  );
  align-items: center;
  background-color: var(--amplify-internal-button-background-color);
  border-color: var(--amplify-internal-button-border-color);
  border-radius: var(--amplify-components-button-border-radius);
  border-style: var(--amplify-components-button-border-style);
  border-width: var(--amplify-internal-button-border-width);
  box-sizing: border-box;
  color: var(--amplify-internal-button-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--amplify-components-button-font-size);
  font-weight: var(--amplify-components-button-font-weight);
  justify-content: center;
  line-height: var(--amplify-components-button-line-height);
  padding-block-start: var(--amplify-components-button-padding-block-start);
  padding-block-end: var(--amplify-components-button-padding-block-end);
  padding-inline-start: var(--amplify-components-button-padding-inline-start);
  padding-inline-end: var(--amplify-components-button-padding-inline-end);
  transition: all var(--amplify-components-button-transition-duration);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  --amplify-internal-button-disabled-color: var(
    --amplify-components-button-disabled-color
  );
  --amplify-internal-button-disabled-background-color: var(
    --amplify-components-button-disabled-background-color
  );
  --amplify-internal-button-disabled-border-color: var(
    --amplify-components-button-disabled-border-color
  );
  --amplify-internal-button-disabled-text-decoration: initial;
  --amplify-internal-button-loading-background-color: var(
    --amplify-components-button-loading-background-color
  );
  --amplify-internal-button-loading-border-color: var(
    --amplify-components-button-loading-border-color
  );
  --amplify-internal-button-loading-color: var(
    --amplify-components-button-loading-color
  );
  --amplify-internal-button-loading-text-decoration: initial;
}
.amplify-button:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-hover-border-color
  );
  --amplify-internal-button-color: var(--amplify-components-button-hover-color);
}
.amplify-button:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-focus-border-color
  );
  --amplify-internal-button-color: var(--amplify-components-button-focus-color);
  box-shadow: var(--amplify-internal-button-focus-box-shadow);
}
.amplify-button:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-active-color
  );
}
.amplify-button--fullwidth {
  width: 100%;
}
.amplify-button--outlined--info {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-info-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-info-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-info-color
  );
}
.amplify-button--outlined--info:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-info-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-info-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-info-hover-color
  );
}
.amplify-button--outlined--info:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-info-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-info-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-info-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-outlined-info-focus-box-shadow
  );
}
.amplify-button--outlined--info:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-info-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-info-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-info-active-color
  );
}
.amplify-button--outlined--warning {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-warning-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-warning-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-warning-color
  );
}
.amplify-button--outlined--warning:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-warning-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-warning-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-warning-hover-color
  );
}
.amplify-button--outlined--warning:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-warning-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-warning-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-warning-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-outlined-warning-focus-box-shadow
  );
}
.amplify-button--outlined--warning:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-warning-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-warning-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-warning-active-color
  );
}
.amplify-button--outlined--error {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-error-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-error-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-error-color
  );
}
.amplify-button--outlined--error:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-error-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-error-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-error-hover-color
  );
}
.amplify-button--outlined--error:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-error-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-error-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-error-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-outlined-error-focus-box-shadow
  );
}
.amplify-button--outlined--error:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-error-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-error-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-error-active-color
  );
}
.amplify-button--outlined--success {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-success-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-success-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-success-color
  );
}
.amplify-button--outlined--success:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-success-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-success-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-success-hover-color
  );
}
.amplify-button--outlined--success:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-success-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-success-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-success-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-outlined-success-focus-box-shadow
  );
}
.amplify-button--outlined--success:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-success-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-success-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-success-active-color
  );
}
.amplify-button--outlined--overlay {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-overlay-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-overlay-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-overlay-color
  );
}
.amplify-button--outlined--overlay:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-overlay-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-overlay-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-overlay-hover-color
  );
}
.amplify-button--outlined--overlay:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-overlay-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-overlay-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-overlay-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-outlined-overlay-focus-box-shadow
  );
}
.amplify-button--outlined--overlay:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-outlined-overlay-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-outlined-overlay-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-outlined-overlay-active-color
  );
}
.amplify-button--menu {
  border-width: var(--amplify-components-button-menu-border-width);
  background-color: var(--amplify-components-button-menu-background-color);
  justify-content: var(--amplify-components-button-menu-justify-content);
  --amplify-internal-button-disabled-color: var(
    --amplify-components-button-menu-disabled-color
  );
}
.amplify-button--menu:hover {
  color: var(--amplify-components-button-menu-hover-color);
  background-color: var(
    --amplify-components-button-menu-hover-background-color
  );
}
.amplify-button--menu:focus {
  box-shadow: none;
  color: var(--amplify-components-button-menu-focus-color);
  background-color: var(
    --amplify-components-button-menu-focus-background-color
  );
}
.amplify-button--menu:active {
  color: var(--amplify-components-button-menu-active-color);
  background-color: var(
    --amplify-components-button-menu-active-background-color
  );
}
.amplify-button--primary {
  --amplify-internal-button-border-width: var(
    --amplify-components-button-primary-border-width
  );
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-color
  );
  --amplify-internal-button-disabled-border-color: var(
    --amplify-components-button-primary-disabled-border-color
  );
  --amplify-internal-button-disabled-background-color: var(
    --amplify-components-button-primary-disabled-background-color
  );
  --amplify-internal-button-disabled-color: var(
    --amplify-components-button-primary-disabled-color
  );
  --amplify-internal-button-loading-background-color: var(
    --amplify-components-button-primary-loading-background-color
  );
  --amplify-internal-button-loading-border-color: var(
    --amplify-components-button-primary-loading-border-color
  );
  --amplify-internal-button-loading-color: var(
    --amplify-components-button-primary-loading-color
  );
}
.amplify-button--primary:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-hover-color
  );
}
.amplify-button--primary:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-primary-focus-box-shadow
  );
}
.amplify-button--primary:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-active-color
  );
}
.amplify-button--primary--info {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-info-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-info-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-info-color
  );
}
.amplify-button--primary--info:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-info-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-info-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-info-hover-color
  );
}
.amplify-button--primary--info:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-info-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-info-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-info-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-primary-info-focus-box-shadow
  );
}
.amplify-button--primary--info:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-info-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-info-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-info-active-color
  );
}
.amplify-button--primary--warning {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-warning-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-warning-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-warning-color
  );
}
.amplify-button--primary--warning:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-warning-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-warning-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-warning-hover-color
  );
}
.amplify-button--primary--warning:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-warning-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-warning-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-warning-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-primary-warning-focus-box-shadow
  );
}
.amplify-button--primary--warning:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-warning-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-warning-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-warning-active-color
  );
}
.amplify-button--primary--error {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-error-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-error-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-error-color
  );
}
.amplify-button--primary--error:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-error-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-error-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-error-hover-color
  );
}
.amplify-button--primary--error:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-error-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-error-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-error-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-primary-error-focus-box-shadow
  );
}
.amplify-button--primary--error:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-error-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-error-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-error-active-color
  );
}
.amplify-button--primary--success {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-success-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-success-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-success-color
  );
}
.amplify-button--primary--success:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-success-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-success-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-success-hover-color
  );
}
.amplify-button--primary--success:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-success-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-success-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-success-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-primary-success-focus-box-shadow
  );
}
.amplify-button--primary--success:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-success-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-success-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-success-active-color
  );
}
.amplify-button--primary--overlay {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-overlay-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-overlay-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-overlay-color
  );
}
.amplify-button--primary--overlay:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-overlay-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-overlay-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-overlay-hover-color
  );
}
.amplify-button--primary--overlay:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-overlay-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-overlay-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-overlay-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-primary-overlay-focus-box-shadow
  );
}
.amplify-button--primary--overlay:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-primary-overlay-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-primary-overlay-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-primary-overlay-active-color
  );
}
.amplify-button--link {
  --amplify-internal-button-border-width: var(
    --amplify-components-button-link-border-width
  );
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-border-color
  );
  --amplify-internal-button-color: var(--amplify-components-button-link-color);
  --amplify-internal-button-disabled-border-color: var(
    --amplify-components-button-link-disabled-border-color
  );
  --amplify-internal-button-disabled-background-color: var(
    --amplify-components-button-link-disabled-background-color
  );
  --amplify-internal-button-disabled-color: var(
    --amplify-components-button-link-disabled-color
  );
  --amplify-internal-button-loading-background-color: var(
    --amplify-components-button-link-loading-background-color
  );
  --amplify-internal-button-loading-border-color: var(
    --amplify-components-button-link-loading-border-color
  );
  --amplify-internal-button-loading-color: var(
    --amplify-components-button-link-loading-color
  );
}
.amplify-button--link:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-hover-color
  );
}
.amplify-button--link:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-link-focus-box-shadow
  );
}
.amplify-button--link:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-active-color
  );
}
.amplify-button--link--info {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-info-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-info-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-info-color
  );
}
.amplify-button--link--info:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-info-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-info-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-info-hover-color
  );
}
.amplify-button--link--info:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-info-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-info-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-info-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-link-info-focus-box-shadow
  );
}
.amplify-button--link--info:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-info-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-info-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-info-active-color
  );
}
.amplify-button--link--warning {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-warning-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-warning-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-warning-color
  );
}
.amplify-button--link--warning:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-warning-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-warning-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-warning-hover-color
  );
}
.amplify-button--link--warning:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-warning-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-warning-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-warning-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-link-warning-focus-box-shadow
  );
}
.amplify-button--link--warning:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-warning-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-warning-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-warning-active-color
  );
}
.amplify-button--link--error {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-error-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-error-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-error-color
  );
}
.amplify-button--link--error:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-error-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-error-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-error-hover-color
  );
}
.amplify-button--link--error:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-error-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-error-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-error-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-link-error-focus-box-shadow
  );
}
.amplify-button--link--error:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-error-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-error-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-error-active-color
  );
}
.amplify-button--link--success {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-success-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-success-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-success-color
  );
}
.amplify-button--link--success:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-success-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-success-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-success-hover-color
  );
}
.amplify-button--link--success:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-success-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-success-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-success-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-link-success-focus-box-shadow
  );
}
.amplify-button--link--success:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-success-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-success-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-success-active-color
  );
}
.amplify-button--link--overlay {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-overlay-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-overlay-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-overlay-color
  );
}
.amplify-button--link--overlay:hover {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-overlay-hover-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-overlay-hover-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-overlay-hover-color
  );
}
.amplify-button--link--overlay:focus {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-overlay-focus-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-overlay-focus-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-overlay-focus-color
  );
  --amplify-internal-button-focus-box-shadow: var(
    --amplify-components-button-link-overlay-focus-box-shadow
  );
}
.amplify-button--link--overlay:active {
  --amplify-internal-button-background-color: var(
    --amplify-components-button-link-overlay-active-background-color
  );
  --amplify-internal-button-border-color: var(
    --amplify-components-button-link-overlay-active-border-color
  );
  --amplify-internal-button-color: var(
    --amplify-components-button-link-overlay-active-color
  );
}
.amplify-button--destructive {
  border-width: var(--amplify-components-button-destructive-border-width);
  background-color: var(
    --amplify-components-button-destructive-background-color
  );
  border-color: var(--amplify-components-button-destructive-border-color);
  color: var(--amplify-components-button-destructive-color);
  --amplify-internal-button-disabled-border-color: var(
    --amplify-components-button-destructive-disabled-border-color
  );
  --amplify-internal-button-disabled-background-color: var(
    --amplify-components-button-destructive-disabled-background-color
  );
  --amplify-internal-button-disabled-color: var(
    --amplify-components-button-destructive-disabled-color
  );
  --amplify-internal-button-loading-background-color: var(
    --amplify-components-button-destructive-loading-background-color
  );
  --amplify-internal-button-loading-border-color: var(
    --amplify-components-button-destructive-loading-border-color
  );
  --amplify-internal-button-loading-color: var(
    --amplify-components-button-destructive-loading-color
  );
}
.amplify-button--destructive:hover {
  background-color: var(
    --amplify-components-button-destructive-hover-background-color
  );
  border-color: var(--amplify-components-button-destructive-hover-border-color);
  color: var(--amplify-components-button-destructive-hover-color);
}
.amplify-button--destructive:focus {
  background-color: var(
    --amplify-components-button-destructive-focus-background-color
  );
  border-color: var(--amplify-components-button-destructive-focus-border-color);
  color: var(--amplify-components-button-destructive-focus-color);
  box-shadow: var(--amplify-components-button-destructive-focus-box-shadow);
}
.amplify-button--destructive:active {
  background-color: var(
    --amplify-components-button-destructive-active-background-color
  );
  border-color: var(
    --amplify-components-button-destructive-active-border-color
  );
  color: var(--amplify-components-button-destructive-active-color);
}
.amplify-button--warning {
  background-color: var(--amplify-components-button-warning-background-color);
  border-color: var(--amplify-components-button-warning-border-color);
  border-width: var(--amplify-components-button-warning-border-width);
  color: var(--amplify-components-button-warning-color);
  --amplify-internal-button-disabled-text-decoration: none;
  --amplify-internal-button-disabled-border-color: var(
    --amplify-components-button-warning-disabled-border-color
  );
  --amplify-internal-button-disabled-background-color: var(
    --amplify-components-button-warning-disabled-background-color
  );
  --amplify-internal-button-disabled-color: var(
    --amplify-components-button-warning-disabled-color
  );
  --amplify-internal-button-loading-background-color: var(
    --amplify-components-button-warning-loading-background-color
  );
  --amplify-internal-button-loading-border-color: var(
    --amplify-components-button-warning-loading-border-color
  );
  --amplify-internal-button-loading-color: var(
    --amplify-components-button-warning-loading-color
  );
  --amplify-internal-button-loading-text-decoration: none;
}
.amplify-button--warning:hover {
  background-color: var(
    --amplify-components-button-warning-hover-background-color
  );
  border-color: var(--amplify-components-button-warning-hover-border-color);
  color: var(--amplify-components-button-warning-hover-color);
}
.amplify-button--warning:focus {
  background-color: var(
    --amplify-components-button-warning-focus-background-color
  );
  border-color: var(--amplify-components-button-warning-focus-border-color);
  color: var(--amplify-components-button-warning-focus-color);
  box-shadow: var(--amplify-components-button-warning-focus-box-shadow);
}
.amplify-button--warning:active {
  background-color: var(
    --amplify-components-button-warning-active-background-color
  );
  border-color: var(--amplify-components-button-warning-active-border-color);
  color: var(--amplify-components-button-warning-active-color);
}
.amplify-button--small {
  font-size: var(--amplify-components-button-small-font-size);
  padding-block-start: var(
    --amplify-components-button-small-padding-block-start
  );
  padding-block-end: var(--amplify-components-button-small-padding-block-end);
  padding-inline-start: var(
    --amplify-components-button-small-padding-inline-start
  );
  padding-inline-end: var(--amplify-components-button-small-padding-inline-end);
}
.amplify-button--large {
  font-size: var(--amplify-components-button-large-font-size);
  padding-block-start: var(
    --amplify-components-button-large-padding-block-start
  );
  padding-block-end: var(--amplify-components-button-large-padding-block-end);
  padding-inline-start: var(
    --amplify-components-button-large-padding-inline-start
  );
  padding-inline-end: var(--amplify-components-button-large-padding-inline-end);
}
.amplify-button--disabled {
  background-color: var(--amplify-internal-button-disabled-background-color);
  border-color: var(--amplify-internal-button-disabled-border-color);
  color: var(--amplify-internal-button-disabled-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-disabled-text-decoration
  );
  text-decoration: var(--amplify-internal-button-disabled-text-decoration);
  cursor: not-allowed;
}
.amplify-button--disabled:hover {
  background-color: var(--amplify-internal-button-disabled-background-color);
  border-color: var(--amplify-internal-button-disabled-border-color);
  color: var(--amplify-internal-button-disabled-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-disabled-text-decoration
  );
  text-decoration: var(--amplify-internal-button-disabled-text-decoration);
}
.amplify-button--disabled :focus {
  background-color: var(--amplify-internal-button-disabled-background-color);
  border-color: var(--amplify-internal-button-disabled-border-color);
  color: var(--amplify-internal-button-disabled-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-disabled-text-decoration
  );
  text-decoration: var(--amplify-internal-button-disabled-text-decoration);
}
.amplify-button--disabled:active {
  background-color: var(--amplify-internal-button-disabled-background-color);
  border-color: var(--amplify-internal-button-disabled-border-color);
  color: var(--amplify-internal-button-disabled-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-disabled-text-decoration
  );
  text-decoration: var(--amplify-internal-button-disabled-text-decoration);
}
.amplify-button--loading {
  background-color: var(--amplify-internal-button-loading-background-color);
  border-color: var(--amplify-internal-button-loading-border-color);
  color: var(--amplify-components-button-loading-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-loading-text-decoration
  );
  text-decoration: var(--amplify-internal-button-loading-text-decoration);
}
.amplify-button--loading:hover {
  background-color: var(--amplify-internal-button-loading-background-color);
  border-color: var(--amplify-internal-button-loading-border-color);
  color: var(--amplify-components-button-loading-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-loading-text-decoration
  );
  text-decoration: var(--amplify-internal-button-loading-text-decoration);
}
.amplify-button--loading:focus {
  background-color: var(--amplify-internal-button-loading-background-color);
  border-color: var(--amplify-internal-button-loading-border-color);
  color: var(--amplify-components-button-loading-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-loading-text-decoration
  );
  text-decoration: var(--amplify-internal-button-loading-text-decoration);
}
.amplify-button--loading:active {
  background-color: var(--amplify-internal-button-loading-background-color);
  border-color: var(--amplify-internal-button-loading-border-color);
  color: var(--amplify-components-button-loading-color);
  -webkit-text-decoration: var(
    --amplify-internal-button-loading-text-decoration
  );
  text-decoration: var(--amplify-internal-button-loading-text-decoration);
}
.amplify-button__loader-wrapper {
  align-items: var(--amplify-components-button-loader-wrapper-align-items);
  gap: var(--amplify-components-button-loader-wrapper-gap);
}

@media (prefers-reduced-motion: reduce) {
  .amplify-button {
    transition: none;
  }
}
.amplify-dropzone {
  display: block;
  background-color: var(--amplify-components-dropzone-background-color);
  border-color: var(--amplify-components-dropzone-border-color);
  border-width: var(--amplify-components-dropzone-border-width);
  border-style: var(--amplify-components-dropzone-border-style);
  border-radius: var(--amplify-components-dropzone-border-radius);
  color: var(--amplify-components-dropzone-color);
  padding-block: var(--amplify-components-dropzone-padding-block);
  padding-inline: var(--amplify-components-dropzone-padding-inline);
  text-align: var(--amplify-components-dropzone-text-align);
}
.amplify-dropzone--disabled {
  cursor: not-allowed;
  background-color: var(
    --amplify-components-dropzone-disabled-background-color
  );
  border-color: var(--amplify-components-dropzone-disabled-border-color);
  border-width: var(--amplify-components-dropzone-disabled-border-width);
  border-style: var(--amplify-components-dropzone-disabled-border-style);
  border-radius: var(--amplify-components-dropzone-disabled-border-radius);
  color: var(--amplify-components-dropzone-disabled-color);
}
.amplify-dropzone--active {
  background-color: var(--amplify-components-dropzone-active-background-color);
  border-color: var(--amplify-components-dropzone-active-border-color);
  border-width: var(--amplify-components-dropzone-active-border-width);
  border-style: var(--amplify-components-dropzone-active-border-style);
  border-radius: var(--amplify-components-dropzone-active-border-radius);
  color: var(--amplify-components-dropzone-active-color);
}
.amplify-dropzone--rejected {
  background-color: var(
    --amplify-components-dropzone-rejected-background-color
  );
  border-color: var(--amplify-components-dropzone-rejected-border-color);
  border-width: var(--amplify-components-dropzone-rejected-border-width);
  border-style: var(--amplify-components-dropzone-rejected-border-style);
  border-radius: var(--amplify-components-dropzone-rejected-border-radius);
  color: var(--amplify-components-dropzone-rejected-color);
}
.amplify-dropzone--accepted {
  background-color: var(
    --amplify-components-dropzone-accepted-background-color
  );
  border-color: var(--amplify-components-dropzone-accepted-border-color);
  border-width: var(--amplify-components-dropzone-accepted-border-width);
  border-style: var(--amplify-components-dropzone-accepted-border-style);
  border-radius: var(--amplify-components-dropzone-accepted-border-radius);
  color: var(--amplify-components-dropzone-accepted-color);
}

.amplify-field__description {
  color: var(--amplify-components-fieldmessages-description-color);
  font-style: var(--amplify-components-fieldmessages-description-font-style);
  font-size: var(--amplify-components-fieldmessages-description-font-size);
}

.amplify-field__error-message {
  color: var(--amplify-components-fieldmessages-error-color);
  font-size: var(--amplify-components-fieldmessages-error-font-size);
}

.amplify-heading {
  color: var(--amplify-components-heading-color);
  line-height: var(--amplify-components-heading-line-height);
  display: block;
}
.amplify-heading--truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.amplify-heading--1 {
  font-size: var(--amplify-components-heading-1-font-size);
  font-weight: var(--amplify-components-heading-1-font-weight);
}

.amplify-heading--2 {
  font-size: var(--amplify-components-heading-2-font-size);
  font-weight: var(--amplify-components-heading-2-font-weight);
}

.amplify-heading--3 {
  font-size: var(--amplify-components-heading-3-font-size);
  font-weight: var(--amplify-components-heading-3-font-weight);
}

.amplify-heading--4 {
  font-size: var(--amplify-components-heading-4-font-size);
  font-weight: var(--amplify-components-heading-4-font-weight);
}

.amplify-heading--5 {
  font-size: var(--amplify-components-heading-5-font-size);
  font-weight: var(--amplify-components-heading-5-font-weight);
}

.amplify-heading--6 {
  font-size: var(--amplify-components-heading-6-font-size);
  font-weight: var(--amplify-components-heading-6-font-weight);
}
