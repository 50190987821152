:root {
  --primary-color: #0063b0; /* Your vibrant orange */
  --secondary-primary-color: #1d6ec0;
  --text-color: #333; /* A soft black for text */
  --background-color: #cccccc; /* White for certain backgrounds */
  --neutral-color: #f4f7fa; /* A neutral color for page backgrounds */
  --link-hover-color: #cc8400; /* A darker orange for link hover states */
  --secondary-color: #581a0d;
}

.central-container {
  max-width: 1200px; /* Or the max-width you prefer */
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem; /* Add some padding */
}

.App {
  text-align: center;
  /* Remove max-width and margin from here if using central-container */
}

.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--background-color);
}

.App-link {
  color: var(--primary-color);
}

.App-link:hover {
  color: var(--link-hover-color); /* Darker orange on hover */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */

.NavBar {
  /* Ensure the NavBar fills the central container but doesn't exceed it */
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--neutral-color);
  color: var(--text-color);
}

/* Styles for NavBar */
nav {
  border-bottom: 1px solid var(--primary-color); /* Orange bottom border for nav */
  padding: 10px 0; /* Add some padding above and below the navigation links */
  margin-bottom: 20px; /* Add some margin below the NavBar to separate from the content */
}

nav ul {
  list-style-type: none; /* Remove bullet points from list items */
  padding: 0; /* Remove padding */
  text-align: center; /* Center align the list items */
}

nav ul li {
  display: inline; /* Display list items inline */
  margin-right: 20px; /* Add some margin to the right of each list item */
}

/* You can also add :hover styles for your navigation links if needed */
nav ul li:hover {
  text-decoration: underline; /* Example hover effect */
  cursor: pointer; /* Change cursor to pointer on hover */
}

/* Add styles for the active link if needed */
nav ul li.active {
  font-weight: bold; /* Make the active link bold */
}

/* Add responsive styles if needed */
@media (max-width: 768px) {
  nav ul li {
    display: block; /* Stack the list items vertically on small screens */
    margin: 10px 0; /* Add some vertical margin */
  }
}

/* Styles to limit the form width and center it */
.View {
  max-width: 800px; /* Adjust this value as needed */
  margin: 0 auto; /* Center the form in the page */
  padding: 20px; /* Add some padding around the form */
}

/* Ensure the header bar is centered and not wider than the form */
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f8f9fa;
  border-bottom: 2px solid #e9ecef;
  padding: 0 1rem;
}

/* Styles for the hamburger icon */
.HamburgerMenu {
  position: fixed;
  /* Adjust top and right values as necessary */
  top: 0;
  right: 0;
  /* Ensure it doesn't overlap the central container */
  max-width: calc(100% - 1200px);
  left: calc((100% - 1200px) / 2);
}

/* Navigation menu overlay */
.menu-overlay {
  display: none; /* Initially hidden */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 165, 0, 0.4); /* Semi-transparent orange */
  z-index: 1000; /* High z-index to ensure it's above other content */
}

/* Navigation menu */
.nav-menu {
  display: none; /* Align menu items in a column */
  position: fixed;
  top: 0;
  right: 0; /* Align to the right */
  width: 250px; /* Fixed width */
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1100; /* Even higher z-index to be above the overlay */
}

/* Show the overlay and drawer when active */
.menu-overlay.active,
.nav-menu.active {
  display: block;
}

.hamburger-icon {
  /* Styling for the hamburger icon */
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px 0;
  background-color: var(--primary-color);
  transition: all 0.3s ease-in-out;
}

/* Hamburger menu transformation when active */
/* .hamburger-menu.active .hamburger-icon { */
/* Add transformations for the hamburger icon to create an 'X' */
/* } */

/* Navigation Drawer/Menu styles */
.nav-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  /* More styles for the nav menu */
}

/* Update View styles to match the header width */
.View {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

/* Professional color scheme for the rest of the elements */
body {
  background-color: var(--neutral-color); /* Neutral background for the body */
  color: var(--text-color); /* Text in soft black for readability */
}

/* Styles for links and buttons */
a,
button {
  color: var(--primary-color); /* Orange for links and buttons */
}

/* Card styles */
.Card {
  background-color: var(--background-color); /* White background for cards */
  border: 2px solid var(--primary-color); /* Orange border for cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

/* Shared CSS file, e.g., App.css */

.central-content {
  max-width: 1200px; /* Or your preferred max width */
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem; /* Add some padding for better appearance */
}

#form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#form-title {
  margin-top: 0;
  font-weight: 400;
  text-align: center;
}

#question-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  height: 50px;
  user-select: none;
}

#question-slider::before {
  content: " ";
  position: absolute;
  height: 3px;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
}

#question-slider input,
#question-slider label {
  box-sizing: border-box;
  flex: 1;
  user-select: none;
  cursor: pointer;
}

#question-slider label {
  display: inline-block;
  position: absolute;
  height: 100%;
  user-select: none;
}


#question-slider label::before {
  content: attr(data-debt-amount);
  position: absolute;
  left: 50%;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding-top: 10px;
  transform: translate(-50%, 45px);
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  opacity: 0.85;
  transition: all 0.15s ease-in-out;
}

@media screen and (max-width: 639px) {
  #question-slider label::before {
    font-size: 12px;
  }
}

#question-slider label::after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  background: #fff;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

#question-slider label:hover::after {
  transform: translate(-50%, -50%) scale(1.25);
}

#question-slider input {
  display: none;
}

#question-slider input:checked + label::before {
  font-weight: 800;
  opacity: 1;
}

#question-slider input:checked + label::after {
  border-width: 4px;
  transform: translate(-50%, -50%) scale(1.75);
}

#question-pos {
  display: block;
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background: #000;
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  transform: translate(-50%, -50%) scale(2);
  border: 2px solid #fff;
  z-index: 2;
}

/* State 1: Missing */
#question-pos.missing {
  background: red; /* Gray color */
}

/* State 2: Poor */
#question-pos.poor {
  background: orange; /* Red color */
}

/* State 3: OK */
#question-pos.ok {
  background: gold; /* Gold color */
}

/* State 4: Good */
#question-pos.good {
  background: green; /* Green color */
}

/* Default state for slider */
#question-slider::before {
  background: gray;
}

/* State 1: Missing for slider */
#question-slider.missing::before {
  background: red; /* Gray color */
}

/* State 2: Poor for slider */
#question-slider.poor::before {
  background: orange; /* Red color */
}

/* State 3: OK for slider */
#question-slider.ok::before {
  background: gold; /* Gold color */
}

/* State 4: Good for slider */
#question-slider.good::before {
  background: green; /* Green color */
}

/* Default state for label */
#question-slider label::after {
  border-color: gray;
}

/* State 1: Missing for label */
#question-slider.missing label::after {
  border-color: red; /* Gray color */
}

/* State 2: Poor for label */
#question-slider.poor label::after {
  border-color: orange; /* Red color */
}

/* State 3: OK for label */
#question-slider.ok label::after {
  border-color: gold; /* Gold color */
}

/* State 4: Good for label */
#question-slider.good label::after {
  border-color: green; /* Green color */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
  }
}
